@font-face {
  font-family: 'Satoshi Variable';
  src: url('Satoshi-Variable.eot');
  src: url('Satoshi-Variable.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('Satoshi-Variable.woff2') format('woff2'), /* Super Modern Browsers */
       url('Satoshi-Variable.woff') format('woff'), /* Pretty Modern Browsers */
       url('Satoshi-Variable.ttf')  format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Satoshi Variable Bold';
  src: url('Satoshi-Bold.eot');
  src: url('Satoshi-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('Satoshi-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('Satoshi-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('Satoshi-Bold.ttf')  format('truetype');
  font-display: block;
}
